import axios from "../../axios/axios"


export const KnowledgeRepository = {
    getAll: () => {
        return axios.get(`/api/knowledge`, {
            headers: {"Content-Type": "application/json"}
        });
    },
    delete: (id) => {
        return axios.delete(`/api/knowledge/${id}`)
    },
    create: (slot_id, data, file, resourceType, url, pdfLink, setUploadProgress) => {
        let formData = new FormData()
        formData.append("pdfFile", file)
        formData.append("slot_id", slot_id)
        formData.append("data", data)
        formData.append("resource_type", resourceType)
        formData.append("url", url)
        formData.append("pdfLink", pdfLink)
        return axios.post('/api/knowledge',
            data = formData,
            {
                headers: {"Content-Type": "multipart/form-data"},
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            })
    },
    edit: (id, slot_id, data, file, resourceType, url, pdfLink, setUploadProgress) => {
        let formData = new FormData()
        formData.append("pdfFile", file)
        formData.append("slot_id", slot_id)
        formData.append("data", data)
        formData.append("resource_type", resourceType)
        formData.append("url", url)
        formData.append("pdfLink", pdfLink)
        return axios.post(`/api/knowledge/${id}/edit`,
            data = formData,
            {
                headers: {"Content-Type": "multipart/form-data"},
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            })
    },
    updateData: (id, data) => {
        let params = {
            "data": data
        }
        return axios.post(`/api/knowledge/${id}`, params)
    },
    getBySlotId: (slotId) => {
        return axios.get(`/api/knowledge/slot/${slotId}`, {
            headers: {"Content-Type": "application/json"}
        });
    },
    publish: (doc) => {
        return axios.get(`/api/knowledge/${doc.id}/publish`)
    },
    unpublish: (doc) => {
        return axios.get(`/api/knowledge/${doc.id}/unpublish`)
    },
    republish:(doc) => {
        return axios.get(`/api/knowledge/${doc.id}/republish`)
    },
    preview:(docId) => {
        return axios.get(`/api/knowledge/${docId}`)
    },
    deleteByUUID(knowledgeId, uuid) {
        return axios.delete(`/api/knowledge/doc_id/${knowledgeId}/uuid/${uuid}`)
    }
}