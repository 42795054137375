import {MainLayout} from "../../layout/main-layout/MainLayout";
import {BlockCard} from "../../components/block-card/BlockCard";
import {
    Box,
    Button,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {IconPlus} from "@tabler/icons-react";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {paginationInitialState} from "../../utils/paginationInitialState";
import {SlotRepository} from "./SlotRepository";
import {confirmDialogOptions} from "../../utils/confirmDialogOptions"
import {confirm} from "react-confirm-box";
import {CreateSlot} from "./CreateSlot"

export const SlotPage = () => {
    const [pagination, setPagination] = useState({...paginationInitialState});
    const [slots, setSlots] = useState([]);
    const [createSlot, setCreateSlot] = useState(false);

    const fetchData = () => {
        SlotRepository.getAll()
            .then(response => {
                debugger;
                setSlots(response.data);
            })
            .catch(error => console.log(error))
    }

    const onDeleteHandle = async (topic) => {
        const response = await confirm(`Are you sure you want to delete '${topic?.name}'`, confirmDialogOptions)
        if (response) {
            SlotRepository.delete(topic.id)
                .then(response => {
                    fetchData();
                    toast.success("Document deleted");
                })
                .catch(error => toast.error(error.message))
        }
    }


    const tableBody = slots.map((slot, index) => {

        return (
            <TableRow hover>
                <TableCell>{slot.id}</TableCell>
                <TableCell>{slot.name}</TableCell>
                <TableCell>{slot.topic_name}</TableCell>
                <TableCell>{slot.org_name}</TableCell>
                <TableCell>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>

                        <Link href={"/knowledge/" + slot.org_id + "/" + slot.topic_id + "/" + slot.id} variant="body"
                              align={"justify"}>
                            Open
                        </Link>

                        <Button onClick={() => onDeleteHandle(slot)} title={"Delete"} component={Button}
                                color={"primary"}>
                            Delete
                        </Button>
                    </Stack>

                </TableCell>
            </TableRow>
        )
    })

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <MainLayout>
            <CreateSlot
                onSuccess={() => {
                    setCreateSlot(null);
                    fetchData();
                }}
                onCloseHandle={() => setCreateSlot(null)}
                show={createSlot}
            />

            <BlockCard sx={{
                flex: 1,
                maxHeight: "100%",
                maxWidth: "xd",
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                p: 2
            }}>
                <Stack direction={"row"}>
                    <Button variant={"outlined"} size={"small"} sx={{borderRadius: 1}} startIcon={<IconPlus/>}
                            onClick={() => setCreateSlot(true)}>
                        Create Slot
                    </Button>
                </Stack>
                <Box sx={{flex: 1, overflowY: "auto"}}>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontWeight: "700"}}>Id</TableCell>
                                <TableCell sx={{fontWeight: "700"}}>Name</TableCell>
                                <TableCell sx={{fontWeight: "700"}}>Topic Name</TableCell>
                                <TableCell sx={{fontWeight: "700"}}>Organization Name</TableCell>
                                <TableCell sx={{fontWeight: "700"}}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableBody}
                        </TableBody>
                    </Table>
                </Box>
                <Stack justifyContent={"space-between"} direction={"row"}>
                    <div/>
                    <TablePagination
                        component="div"
                        count={pagination.totalElements}
                        page={pagination.page}
                        onPageChange={(e, newPage) => setPagination({...pagination, page: newPage})}
                        rowsPerPage={pagination.size}
                        onRowsPerPageChange={(e) => setPagination({
                            ...pagination,
                            size: parseInt(e.target.value, 10),
                            page: 0
                        })}
                    />
                </Stack>
            </BlockCard>
        </MainLayout>
    )
}