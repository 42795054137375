export const categoriesMockData = [
  {
    name: "Prepaid",
    description: "A category for telecommunication system where users pay for services before using them.",
    color: "#FFC300"
  },
  {
    name: "Postpaid",
    description: "A category for telecommunication system where users pay for services after using them.",
    color: "#FF5733"
  },
  {
    name: "Internet",
    description: "A category for telecommunication system that provides internet services.",
    color: "#C70039"
  },
  {
    name: "Mobile Network",
    description: "A category for telecommunication system that provides mobile network services.",
    color: "#900C3F"
  },
  {
    name: "Credit",
    description: "A category for telecommunication system where users can avail credit services.",
    color: "#581845"
  },
  {
    name: "VoIP",
    description: "A category for telecommunication system that provides Voice over Internet Protocol services.",
    color: "#DAF7A6"
  },
  {
    name: "Roaming",
    description: "A category for telecommunication system where users can avail roaming services.",
    color: "#FFC300"
  },
  {
    name: "SMS",
    description: "A category for telecommunication system where users can send and receive SMS messages.",
    color: "#FF5733"
  },
  {
    name: "MMS",
    description: "A category for telecommunication system where users can send and receive MMS messages.",
    color: "#C70039"
  },
  {
    name: "Call Waiting",
    description: "A category for telecommunication system where users can avail call waiting services.",
    color: "#900C3F"
  },
  {
    name: "Call Forwarding",
    description: "A category for telecommunication system where users can avail call forwarding services.",
    color: "#581845"
  },
  {
    name: "Conference Call",
    description: "A category for telecommunication system where users can avail conference call services.",
    color: "#DAF7A6"
  },
  {
    name: "Data Roaming",
    description: "A category for telecommunication system where users can avail data roaming services.",
    color: "#FFC300"
  },
  {
    name: "Home Network",
    description: "A category for telecommunication system that provides home network services.",
    color: "#FF5733"
  },
  {
    name: "International Calling",
    description: "A category for telecommunication system where users can avail international calling services.",
    color: "#C70039"
  },
  {
    name: "Local Calling",
    description: "A category for telecommunication system where users can avail local calling services.",
    color: "#900C3F"
  },
  {
    name: "Long Distance Calling",
    description: "A category for telecommunication system where users can avail long distance calling services.",
    color: "#581845"
  },
  {
    name: "Data Plans",
    description: "A category for telecommunication system where users can avail data plans services.",
    color: "#DAF7A6"
  },
  {
    name: "Family Plans",
    description: "A category for telecommunication system where users can avail family plans services.",
    color: "#FFC300"
  }
]