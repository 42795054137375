import {MainLayout} from "../../layout/main-layout/MainLayout";
import {BlockCard} from "../../components/block-card/BlockCard";
import {
    Box,
    Button, FormControl, IconButton, InputLabel,
    Link, Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {IconPlus} from "@tabler/icons-react";
import {IconMagnet} from "@tabler/icons-react";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {paginationInitialState} from "../../utils/paginationInitialState";
import {KnowledgeRepository} from "./KnowledgeRepository";
import {confirmDialogOptions} from "../../utils/confirmDialogOptions"
import {confirm} from "react-confirm-box";
import {CreateKnowledge} from "./CreateKnowledge"
import MenuItem from "@mui/material/MenuItem";
import {OrganizationRepository} from "../organizations/OrganizationRepository";
import {TopicRepository} from "../topics/TopicRepository";
import {SlotRepository} from "../slots/SlotRepository";
import {useParams} from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    DeleteForever,
    Edit,
    Done,
    UnpublishedOutlined,
    Publish,
    PublishedWithChangesOutlined, Preview
} from "@mui/icons-material";
import {EditKnowledge} from "./EditKnowledge";
import {AddMetadata} from "../metadata/AddMetadata";
import Typography from "@mui/material/Typography";
import {PreviewKnowledge} from "./PreviewKnowledge";

export const KnowledgePage = (params) => {
    let {org_id, topic_id, slot_id} = useParams();
    const [pagination, setPagination] = useState({...paginationInitialState});
    const [knowledge, setKnowledge] = useState([]);
    const [createKnowledge, setCreateKnowledge] = useState(false);
    const [showMetadata, setShowMetadata] = useState(false)
    const [showPreview, setShowPreview] = useState(false)
    const [editKnowledge, setEditKnowledge] = useState(false);
    const [editKnowledgeItem, setEditKnowledgeItem] = useState({});
    const [organizations, setOrganizations] = useState([]);
    const [topics, setTopics] = useState([]);
    const [slots, setSlots] = useState([]);
    const [orgId, setOrgId] = useState(org_id);
    const [topicId, setTopicId] = useState(topic_id);
    const [slotId, setSlotId] = useState(slot_id);
    const [publishLoader, setPublishLoader] = useState({})

    const updatePublishLoader = (id, status) => {
        const updatedItems = {...publishLoader};
        updatedItems[id] = status
        setPublishLoader(updatedItems)
    }

    const fetchData = () => {
        KnowledgeRepository.getAll()
            .then(response => {
                setKnowledge(response.data);
            })
            .catch(error => console.log(error))
    }

    const onEditHandle = (knowledge) => {
        setEditKnowledge(true)
        setEditKnowledgeItem(knowledge)
    }

    const onAddMetadataHandle = (knowledge) => {
        setShowMetadata(true)
        setEditKnowledgeItem(knowledge)
    }

    const onPreviewDocumentHandle = (knowledge) => {
        setShowPreview(true)
        setEditKnowledgeItem(knowledge)
    }

    const onSubmitMetadataHandle = (knowledge) => {
        debugger;
        KnowledgeRepository.republish(knowledge);
        setShowMetadata(false)
    }

    const onDeleteHandle = async (knowledge) => {
        const response = await confirm(`Are you sure you want to delete '${knowledge?.id}'`, confirmDialogOptions)
        if (response) {
            KnowledgeRepository.delete(knowledge.id)
                .then(response => {
                    fetchData();
                    toast.success("Document deleted");
                })
                .catch(error => toast.error(error.message))
        }
    }

    const organizationMenuItems = organizations.map((o, index) => {
        return (
            <MenuItem value={o.id}>{o.name}</MenuItem>
        )
    })

    const topicMenuItems = topics.map((o, index) => {
        return (
            <MenuItem value={o.id}>{o.name}</MenuItem>
        )
    })

    const slotMenuItems = slots.map((o, index) => {
        return (
            <MenuItem value={o.id}>{o.name}</MenuItem>
        )
    })

    const tableBody = knowledge.map((k, index) => {

        return (
            <TableRow key={k.id}>
                <TableCell>{k.id}</TableCell>
                <TableCell>{k.slot_name}</TableCell>
                <TableCell>{k.resource_type}</TableCell>
                <TableCell>
                    {k.resource_type == "onlinepdf" && k.document}
                    {k.resource_type == "pdf" && k.document}
                    {k.resource_type == "text" && k.data}
                    {k.resource_type == "url" && k.document}</TableCell>
                <TableCell>
                    {k.resource_type == "pdf" &&
                        <Link target="_blank"
                              href={process.env.REACT_APP_KB_GATEWAY + "/api/knowledge/download/" + k.document}>Link</Link>
                    }
                    {(k.resource_type === "onlinepdf" || k.resource_type === "url") &&
                        <Link target="_blank" href={k.document}>Link</Link>
                    }
                </TableCell>
                <TableCell>
                    <Stack direction={"row"}>
                        <Typography sx={{"py": 2}}>
                            {k.num_metadata}
                        </Typography>
                        <IconButton
                            title={"Add MetaField"}
                            onClick={() => onAddMetadataHandle(k)} title={"Add MetaField"} component={Button}
                            color={"primary"}>
                            <IconMagnet/>
                        </IconButton>
                    </Stack>
                </TableCell>
                <TableCell align={"center"}>
                    <Stack direction={"row"}>
                        <IconButton
                            title={"Preview Data"}
                            onClick={() => onPreviewDocumentHandle(k)} title={"Preview Data"} component={Button}
                            color={"primary"}>
                            <Preview/>
                        </IconButton>
                        <IconButton
                            title={"Delete"}
                            onClick={() => onDeleteHandle(k)} title={"Delete"} component={Button}
                            color={"primary"}>
                            <DeleteForever/>
                        </IconButton>
                        <IconButton
                            title={"Edit"}
                            onClick={() => onEditHandle(k)} title={"Edit"} component={Button}
                            color={"primary"}>
                            <Edit/>
                        </IconButton>
                        {k.published &&
                            <LoadingButton
                                title={"Unpublish"}
                                loading={publishLoader[k.id]}
                                onClick={() => unPublish(k)}
                                startIcon={<UnpublishedOutlined/>}>
                                Unpublish
                            </LoadingButton>
                        }
                        {!k.published &&
                            <LoadingButton
                                title={"Publish"}
                                loading={publishLoader[k.id]}
                                onClick={() => publish(k)}
                                startIcon={<PublishedWithChangesOutlined/>}
                                variant={"outlined"}
                                color={"success"}>
                                Publish
                            </LoadingButton>
                        }
                    </Stack>

                </TableCell>
            </TableRow>
        )
    })

    useEffect(() => {
        if (!slot_id) {
            fetchData();
        }
    }, [])

    useEffect(() => {
        OrganizationRepository.getAll().then(response => {
            setOrganizations(response.data.organizations)
        }).catch(error => console.log(error))

    }, [])

    useEffect(() => {
        if (orgId) {
            TopicRepository.getAllByOrgId(orgId).then(response => {
                setTopics(response.data)
            }).catch(error => console.log(error))
        }

    }, [orgId])

    useEffect(() => {
        if (topicId) {
            SlotRepository.getAllByTopicId(topicId).then(response => {
                setSlots(response.data)
            }).catch(error => console.log(error))
        }
    }, [topicId])

    const fetchBySlotId = (slotId) => {
        if (slotId) {
            KnowledgeRepository.getBySlotId(slotId)
                .then(response => {
                    setKnowledge(response.data);
                })
                .catch(error => console.log(error))
        }
    }

    useEffect(() => {
        fetchBySlotId(slotId)
    }, [slotId])

    const publish = (document) => {
        updatePublishLoader(document.id, true)
        KnowledgeRepository.publish(document).then(response => {
            if (slotId) {
                fetchBySlotId(slotId)
            } else {
                fetchData()
            }
        }).catch(error => console.log(error))
            .finally(() => updatePublishLoader(document.id, false))
    }

    const unPublish = (document) => {
        updatePublishLoader(document.id, true)
        KnowledgeRepository.unpublish(document).then(response => {
            if (slotId) {
                fetchBySlotId(slotId)
            } else {
                fetchData()
            }
        }).catch(error => console.log(error))
            .finally(() => updatePublishLoader(document.id, false))
    }

    return (
        <MainLayout>
            <CreateKnowledge
                onSuccess={() => {
                    setCreateKnowledge(null);
                    if (slotId) {
                        fetchBySlotId(slotId)
                    } else {
                        fetchData()
                    }
                }}
                onCloseHandle={() => setCreateKnowledge(null)}
                show={createKnowledge}
                slotIdParam={slotId}
                topicIdParam={topicId}
                orgIdParam={orgId}
            />

            <EditKnowledge
                onSuccess={() => {
                    setEditKnowledge(false);
                    if (slotId) {
                        fetchBySlotId(slotId)
                    } else {
                        fetchData()
                    }
                }}
                onCloseHandle={() => setEditKnowledge(false)}
                show={editKnowledge}
                knowledgeItem={editKnowledgeItem}
            />

            <AddMetadata show={showMetadata}
                         knowledgeItem={editKnowledgeItem}
                         onCloseHandle={() => {
                             setShowMetadata(false)
                         }}
                         onSubmit={onSubmitMetadataHandle}
            />

            <PreviewKnowledge show={showPreview}
                              knowledgeItem={editKnowledgeItem}
                              onCloseHandle={() => {
                                  setShowPreview(false)
                              }}
            />


            <BlockCard sx={{
                flex: 1,
                maxHeight: "100%",
                maxWidth: "xd",
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                p: 2
            }}>
                <Stack direction={"column"} sx={{py: 2}}>
                    <Button variant={"outlined"} size={"small"} sx={{borderRadius: 1, width: "180px"}}
                            startIcon={<IconPlus/>}
                            onClick={() => setCreateKnowledge(true)}>
                        Create Knowledge
                    </Button>
                    <Stack direction={"row"} sx={{mt: 2}}>
                        <FormControl sx={{px: 2, minWidth: 300}}>
                            <InputLabel htmlFor="organization">Organization</InputLabel>
                            <Select value={orgId}
                                    fullWidth
                                    className={"px-1 border overflow-y"}
                                    rowsMin={3}
                                    rowsMax={10}
                                    autoWidth
                                    inputProps={{
                                        name: 'max-width',
                                        id: 'organization',
                                    }}
                                    label="Organization"
                                    variant="standard"
                                    onChange={event => setOrgId(event.target.value)}>
                                {organizationMenuItems}
                            </Select>
                        </FormControl>
                        <FormControl sx={{px: 2, minWidth: 300}}>
                            <InputLabel htmlFor="topic">Topic</InputLabel>
                            <Select value={topicId}
                                    fullWidth
                                    className={"px-1 border overflow-y"}
                                    rowsMin={3}
                                    rowsMax={10}
                                    autoWidth
                                    inputProps={{
                                        name: 'max-width',
                                        id: 'topic',
                                    }}
                                    label="Topic"
                                    variant="standard"
                                    onChange={event => setTopicId(event.target.value)}>
                                {topicMenuItems}
                            </Select>
                        </FormControl>
                        <FormControl sx={{px: 2, minWidth: 300}}>
                            <InputLabel htmlFor="slot">Slot</InputLabel>
                            <Select value={slotId}
                                    fullWidth
                                    className={"px-1 border overflow-y"}
                                    rowsMin={3}
                                    rowsMax={10}
                                    autoWidth
                                    inputProps={{
                                        name: 'max-width',
                                        id: 'topic',
                                    }}
                                    label="Topic"
                                    variant="standard"
                                    onChange={event => setSlotId(event.target.value)}>
                                {slotMenuItems}
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
                <Box sx={{flex: 1, overflowY: "auto"}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontWeight: "700"}}>Id</TableCell>
                                <TableCell sx={{fontWeight: "700"}}>Slot Name</TableCell>
                                <TableCell sx={{fontWeight: "700"}}>Resource Type</TableCell>
                                <TableCell sx={{fontWeight: "700"}}>Data</TableCell>
                                <TableCell sx={{fontWeight: "700"}}>URL</TableCell>
                                <TableCell sx={{fontWeight: "700"}}>Metadata</TableCell>
                                <TableCell sx={{fontWeight: "700"}} align={"center"}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableBody}
                        </TableBody>
                    </Table>
                </Box>
                <Stack justifyContent={"space-between"} direction={"row"}>
                    <div/>
                    <TablePagination
                        component="div"
                        count={pagination.totalElements}
                        page={pagination.page}
                        onPageChange={(e, newPage) => setPagination({...pagination, page: newPage})}
                        rowsPerPage={pagination.size}
                        onRowsPerPageChange={(e) => setPagination({
                            ...pagination,
                            size: parseInt(e.target.value, 10),
                            page: 0
                        })}
                    />
                </Stack>
            </BlockCard>
        </MainLayout>
    )
}