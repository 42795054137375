import {ThemeProvider} from "@mui/material";
import {theme} from "./config/Theme";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Navigate, Route, Routes} from "react-router-dom";
import {ProjectLayout} from "./layout/project-layout/ProjectLayout";
import {OrganizationPage} from "./pages/organizations/OrganizationPage";
import {ProjectHomePage} from "./pages/project/home/ProjectHomePage";
import {ProjectTextsPage} from "./pages/project/texts/ProjectTextsPage";
import {ProjectCategoryPage} from "./pages/project/category/ProjectCategoryPage";
import {TopicSharp} from "@mui/icons-material";
import {TopicPage} from "./pages/topics/TopicPage";
import {SlotPage} from "./pages/slots/SlotPage";
import {ResourcePage} from "./pages/resources/ResourcePage";
import {KnowledgePage} from "./pages/knowledge/KnowledgePage";


function App() {
    return (
        <ThemeProvider theme={theme}>
            <ToastContainer position={"bottom-right"}/>

            <Routes>
                <Route path="/" exact index element={<OrganizationPage/>}/>
                <Route path="/topics" exact index element={<TopicPage/>}/>
                <Route path="/slots" exact index element={<SlotPage/>}/>
                <Route path="/knowledge" exact index element={<KnowledgePage/>}/>
                <Route path="/knowledge/:org_id/:topic_id/:slot_id" exact element={<KnowledgePage/>}/>
                <Route path="/resources" exact index element={<ResourcePage/>}/>
                <Route path="/project/:id/texts" exact index element={<ProjectTextsPage/>}/>
                <Route path="/project/:id/categories" exact index element={<ProjectCategoryPage/>}/>
                <Route path="/project/:id/text/:textId" exact index
                       element={<ProjectLayout>Project text for annotation</ProjectLayout>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>

        </ThemeProvider>
    );
}

export default App;
