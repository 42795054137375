import {ProjectLayout} from "../../../layout/project-layout/ProjectLayout";
import {
  Box,
  Button,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";
import {IconPlus} from "@tabler/icons-react";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {useState} from "react";
import {paginationInitialState} from "../../../utils/paginationInitialState";
import {invertColor} from "../../../utils/invertColor";
import {categoriesMockData} from "../../../_mock/categoriesMockData";

export const ProjectCategoryPage = () => {
  const [categories, setCategories] = useState([...categoriesMockData]);
  const [pagination, setPagination] = useState({...paginationInitialState, totalElements: categoriesMockData?.length});


  return (
    <ProjectLayout>
      <Stack direction={"column"}>
        <Stack direction={"row"}>
          <Button variant={"outlined"} size={"small"} sx={{borderRadius: 1}} startIcon={<IconPlus />} onClick={() => toast.info("Open Modal")}>
            Add Category
          </Button>
        </Stack>
        <Box sx={{flex: 1}}>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight: "700"}}>Name</TableCell>
                <TableCell sx={{fontWeight: "700"}}>Description</TableCell>
                <TableCell sx={{fontWeight: "700"}}>Color</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                categories?.slice((pagination.page * pagination.size), (pagination.page * pagination.size) + pagination.size)?.map(category => {
                  return (
                    <TableRow key={category?.id || category?.name} hover>
                      <TableCell>{category.name}</TableCell>
                      <TableCell>{category.description}</TableCell>
                      <TableCell><Chip variant={"contained"} label={category?.color} sx={{bgcolor: category?.color, color: invertColor(category?.color), fontWeight: 'bold'}} /></TableCell>
                      <TableCell>
                        <Button color={"primary"}>
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </Box>
        <Stack justifyContent={"space-between"} direction={"row"}>
          <div/>
          <TablePagination
            component="div"
            count={pagination.totalElements}
            page={pagination.page}
            onPageChange={(e, newPage) => setPagination({...pagination, page: newPage})}
            rowsPerPage={pagination.size}
            onRowsPerPageChange={(e) => setPagination({...pagination, size: parseInt(e.target.value, 10), page: 0})}
          />
        </Stack>
      </Stack>
    </ProjectLayout>
  )
}