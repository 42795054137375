import {Box, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar} from "@mui/material";
import {NavLink, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {IconCategory, IconDatabase, IconHome, IconMenu} from "@tabler/icons-react";

export const SideBar = ({open, onClose}) => {
  const {id} = useParams();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const newItems = [];
    if (id) {
      newItems.push({ path: `/project/${id}`, label: "Home", icon: <IconHome />, end: true });
      newItems.push({ path: `/project/${id}/texts`, label: "Documents", icon: <IconDatabase /> });
      newItems.push({ path: `/project/${id}/categories`, label: "Categories", icon: <IconCategory /> });
    }
    setMenuItems(newItems);
  }, [id])

  const items = menuItems?.map(item => {
    return (
      <ListItemButton key={item?.path} className={"nav-link"} component={NavLink} end={item?.end || false} to={item?.path} >
        <ListItemIcon>{item?.icon}</ListItemIcon>
        <ListItemText primary={item?.label} />
      </ListItemButton>
    )
  })

  return (
    <>
      <Box
        sx={{
          maxWidth: "300px",
          width: "100%",
          display: {xs: "none", md: "flex"},
          flexDirection: "column",
          position: "sticky",
          alignSelf: "flex-start",
          flex: 1,
          top: 64,
          minHeight: "100%",
          gap: 1
        }}
      >
        <List>
          {items}
        </List>
      </Box>
      <Drawer
        anchor={"left"}
        open={open}
        onClose={onClose}
        sx={{
          height: "100vh",
          width: '100%',
          flex: 1,
          display: {xs: "inherit", md: "none"}
        }}
        PaperProps={{
          sx: {
            maxWidth: "320px",
            width: "100% !important",
          },
        }}
      >
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{p: 2}}>
          <img src={"/logo.svg"} alt={"Logo"} width={50} />
          <IconButton onClick={onClose}>
            <IconMenu />
          </IconButton>
        </Stack>
        <List>
          {items}
        </List>
      </Drawer>
    </>
  )
}