import axios from 'axios';

export const AUTH_TOKEN = 'auth_token';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_KB_GATEWAY}`,
    headers: {
        'Access-Control-Allow-Origin': '*'
    },
});

// instance.interceptors.request.use(
//     config => {
//         const token = localStorage.getItem(AUTH_TOKEN);
//         if (token) config.headers.Authorization = `Bearer ${token}`;
//         return config;
//     },
//     error => {
//         if (error.response.status === 403) {
//             localStorage.removeItem(AUTH_TOKEN);
//             window.location.href = "/login";
//         }
//         return Promise.reject(error)
//     }
// );
//
// instance.interceptors.response.use(
//     config => {return config},
//     error => {
//         if (error.response.status === 403) {
//             localStorage.removeItem(AUTH_TOKEN);
//             window.location.href = "/login";
//         }
//         return Promise.reject(error)
//     }
// )

export default instance;
