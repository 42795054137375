import SweetAlert from "react-bootstrap-sweetalert";
import {Button} from "@mui/material";

export const confirmDialogOptions = {
    render: (message, onConfirm, onCancel) => {
        return (
            <SweetAlert onConfirm={onConfirm}
                        customButtons={
                            <>
                                <Button type={"button"} className=""
                                        onClick={onCancel}>Cancel
                                </Button>
                                <Button type={"button"} className="btn btn-danger m-3" onClick={onConfirm}>Confirm</Button>
                            </>
                        }
                        warning
                        show={true}>
                <h4>{message}</h4>
            </SweetAlert>
        );
    }
}
