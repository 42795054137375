import axios from "../../axios/axios"


export const MetadataRepository = {
    getByKnowledgeItemId: (knowledgeItemId) => {
        return axios.get(`/api/metadata/${knowledgeItemId}`, {
            headers: {"Content-Type": "application/json"}
        });
    },
    deleteById: (id) => {
        return axios.delete(`/api/metadata/${id}`, {
            headers: {"Content-Type": "application/json"}
        })
    },
    addMetadataToKnowledgeItemId: (knowledgeItemId, data) => {
        return axios.post(`/api/metadata/${knowledgeItemId}`, data,{
            headers: {"Content-Type": "application/json"}
        });
    }
}