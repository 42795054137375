import {MainLayout} from "../../layout/main-layout/MainLayout";
import {BlockCard} from "../../components/block-card/BlockCard";
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import {Link} from "react-router-dom";
import {
    Accordion,
    AccordionDetails, AccordionSummary,
    Box,
    Button, ButtonGroup,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {IconPlus} from "@tabler/icons-react";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {paginationInitialState} from "../../utils/paginationInitialState";
import {ResourceRepository} from "./ResourceRepository";
import {confirmDialogOptions} from "../../utils/confirmDialogOptions"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {confirm} from "react-confirm-box";
import {CreateResource} from "./CreateResource"
import Typography from "@mui/material/Typography";
import {sweetAlert} from "react-bootstrap-sweetalert/dist/styles/SweetAlertStyles";

export const ResourcePage = () => {
    const [pagination, setPagination] = useState({...paginationInitialState});
    const [resources, setResources] = useState([]);
    const [createSlot, setCreateSlot] = useState(false);

    const fetchData = () => {
        ResourceRepository.getAll()
            .then(response => {
                debugger;
                setResources(response.data);
            })
            .catch(error => console.log(error))
    }

    const onDeleteHandle = async (topic) => {
        const response = await confirm(`Are you sure you want to delete '${topic?.name}'`, confirmDialogOptions)
        if (response) {
            ResourceRepository.delete(topic.id)
                .then(response => {
                    fetchData();
                    toast.success("Document deleted");
                })
                .catch(error => toast.error(error.message))
        }
    }

    function updateResource(data, id) {
        if (data.jsObject) {
            ResourceRepository.updateData(id, data.jsObject).then(response => {
                toast.success("Document updated");
            })
        } else {
            alert([data.error].map((e)=>"Line: " + e.line + " Reason: "+e.reason))
        }

    }

    const tableBody = resources.map((resource, index) => {

        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content">
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        // flexWrap: 'wrap',
                        flexDirection: 'rows',
                        //
                        // width: '100%',
                        flexGrow: 1
                    }}>
                        <Table>
                            <TableBody>
                                <TableCell sx={{width:"50px"}}>
                                    <Typography sx={{flexShrink: 0}}>
                                        {resource.id}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{width:"120px"}}>
                                    <Typography sx={{flexShrink: 0}}>
                                        {resource.name}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography sx={{color: 'text.secondary'}}>{resource.url}</Typography>
                                </TableCell>
                                <TableCell sx={{width:"120px", alignItems:"right"}}>
                                    <ButtonGroup variant="outlined" aria-label="outlined button group"
                                                 sx={{"align": "right"}}>
                                        <Button onClick={() => onDeleteHandle(resource)} title={"Delete"}
                                                component={Button} color={"primary"}>Delete</Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableBody>
                        </Table>


                    </Box>

                </AccordionSummary>
                <AccordionDetails>
                    <JSONInput
                        id={resource.id}
                        width={"100%"}
                        height={"300px"}
                        sx={{"font-size": "14px"}}
                        onBlur={(data) => updateResource(data, resource.id)}
                        placeholder={JSON.parse(resource.data)}/>

                </AccordionDetails>
            </Accordion>


            // <TableRow hover>
            //     <TableCell>{resource.id}</TableCell>
            //     <TableCell>{resource.name}</TableCell>
            //     <TableCell>{resource.url}</TableCell>
            //     <TableCell>{resource.data}</TableCell>
            //     <TableCell>
            //         <Button onClick={()=>onDeleteHandle(resource)} title={"Delete"} component={Button} color={"primary"}>
            //             Delete
            //         </Button>
            //     </TableCell>
            // </TableRow>
        )
    })

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <MainLayout>
            <CreateResource
                onSuccess={() => {
                    setCreateSlot(null);
                    fetchData();
                }}
                onCloseHandle={() => setCreateSlot(null)}
                show={createSlot}
            />

            <BlockCard sx={{
                flex: 1,
                maxHeight: "100%",
                maxWidth: "xd",
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                p: 2
            }}>
                <Stack direction={"row"}>
                    <Button variant={"outlined"} size={"small"} sx={{borderRadius: 1}} startIcon={<IconPlus/>}
                            onClick={() => setCreateSlot(true)}>
                        Create Resource
                    </Button>
                </Stack>
                <Box sx={{flex: 1, overflowY: "auto"}}>
                    {tableBody}
                    {/*<Table>*/}
                    {/*    <TableHead>*/}
                    {/*        <TableRow>*/}
                    {/*            <TableCell sx={{fontWeight: "700"}}>Id</TableCell>*/}
                    {/*            <TableCell sx={{fontWeight: "700"}}>Name</TableCell>*/}
                    {/*            <TableCell sx={{fontWeight: "700"}}>Url</TableCell>*/}
                    {/*            <TableCell sx={{fontWeight: "700"}}>Data</TableCell>*/}
                    {/*            <TableCell sx={{fontWeight: "700"}}>Actions</TableCell>*/}
                    {/*        </TableRow>*/}
                    {/*    </TableHead>*/}
                    {/*    <TableBody>*/}
                    {/*        */}
                    {/*    </TableBody>*/}
                    {/*</Table>*/}
                </Box>
                <Stack justifyContent={"space-between"} direction={"row"}>
                    <div/>
                    <TablePagination
                        component="div"
                        count={pagination.totalElements}
                        page={pagination.page}
                        onPageChange={(e, newPage) => setPagination({...pagination, page: newPage})}
                        rowsPerPage={pagination.size}
                        onRowsPerPageChange={(e) => setPagination({
                            ...pagination,
                            size: parseInt(e.target.value, 10),
                            page: 0
                        })}
                    />
                </Stack>
            </BlockCard>
        </MainLayout>
    )
}