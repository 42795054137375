import React, {useEffect, useState} from "react";
import {DialogUI} from "../../layout/modal/Modal";
import {
    Box,
    FormControl,
    InputLabel,
    LinearProgress, NativeSelect,
    Select,
    Tab,
    Tabs,
    TextareaAutosize,
    TextField
} from "@mui/material";
import {KnowledgeRepository} from "./KnowledgeRepository";
import {toast} from "react-toastify";
import {OrganizationRepository} from "../organizations/OrganizationRepository";
import MenuItem from "@mui/material/MenuItem";
import {TopicRepository} from "../topics/TopicRepository";
import {SlotRepository} from "../slots/SlotRepository";
import FileUpload from "react-mui-fileuploader"
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ResourceTypes = {
    0: 'text',
    1: 'pdf',
    2: 'onlinepdf',
    3: 'url'
}

export const EditKnowledge = ({onSuccess, onCloseHandle, show, knowledgeItem}) => {
    const [organizations, setOrganizations] = useState([]);
    const [topics, setTopics] = useState([]);
    const [slots, setSlots] = useState([]);
    const [name, setName] = useState();
    const [data, setData] = useState();
    const [url, setUrl] = useState();
    const [filesToUpload, setFilesToUpload] = useState([])
    const [pdfLink, setPdfLink] = useState("")
    const [itemId, setItemId] = useState()
    const [item, setItem] = useState()
    const [error, setError] = useState(null);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [resourceType, setResourceType] = useState(0)
    const [currentDocumentName, setCurrentDocumentName] = useState()
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [orgId, setOrgId] = useState();
    const [topicId, setTopicId] = useState();
    const [slotId, setSlotId] = useState();

    useEffect(() => {
        OrganizationRepository.getAll().then(response => {
            setOrganizations(response.data.organizations)
        }).catch(error => console.log(error))

    }, [])

    // useEffect(() => {
    //     setOrgId(orgIdParam);
    //     setTopicId(topicIdParam);
    //     setSlotId(slotIdParam);
    // }, [orgIdParam, topicIdParam, slotIdParam]);

    const reset = () => {
        setItem(undefined)
        setUrl(undefined)
        setResourceType(undefined)
        setData(undefined)
        setCurrentDocumentName(undefined)
    }

    useEffect(() => {
        reset()
        if (knowledgeItem && knowledgeItem != null && knowledgeItem.id != null) {
            setSlotId(knowledgeItem.slot_id)
            setItemId(knowledgeItem.id)
            setItem(knowledgeItem)
            // setResourceType(ResourceTypes[knowledgeItem.resource_type].index)
            if (knowledgeItem.resource_type === "url") {
                setResourceType(3)
                setUrl(knowledgeItem.url)
            } else if (knowledgeItem.resource_type === "text") {
                setResourceType(0)
                setData(knowledgeItem.data)
            } else if (knowledgeItem.resource_type === "onlinepdf") {
                setResourceType(2)
                setUrl(knowledgeItem.url)
            } else if (knowledgeItem.resource_type === "pdf") {
                setResourceType(1)
                setCurrentDocumentName(knowledgeItem.document)
            }
            SlotRepository.getById(knowledgeItem.slot_id).then(response => {
                setOrgId(response.data.org_id)
                setTopicId(response.data.topic_id)
                // TopicRepository.getAllByOrgId(response.data.org_id).then(response => {
                //     setTopics(response.data)
                //     setTopicId(response.data.topic_id)
                //     SlotRepository.getAllByTopicId(response.data.topic_id).then(response => {
                //         setSlots(response.data)
                //         setSlotId(knowledgeItem.slot_id)
                //     }).catch(error => console.log(error))
                //
                // }).catch(error => console.log(error))
            })

        }

    }, [knowledgeItem])

    useEffect(() => {
        if (orgId) {
            TopicRepository.getAllByOrgId(orgId).then(response => {
                setTopics(response.data)
            }).catch(error => console.log(error))
        }

    }, [orgId])

    useEffect(() => {
        if (topicId) {
            SlotRepository.getAllByTopicId(topicId).then(response => {
                setSlots(response.data)
            }).catch(error => console.log(error))
        }
    }, [topicId])

    const organizationMenuItems = organizations.map((o, index) => {
        return (
            <option value={o.id}>{o.name}</option>
        )
    })

    const topicMenuItems = topics.map((o, index) => {
        return (
            <option value={o.id}>{o.name}</option>
        )
    })

    const slotMenuItems = slots.map((o, index) => {
        return (
            <option value={o.id}>{o.name}</option>
        )
    })

    const onSubmit = () => {
        setDisableSubmitButton(true)
        // Create a form and post it to server
        let formData = new FormData()
        filesToUpload.forEach((file) => formData.append("files", file))
        KnowledgeRepository.edit(itemId, slotId, data, filesToUpload[0], ResourceTypes[resourceType], url, pdfLink, setUploadProgress).then(response => {
            toast.success("Success");
            setDisableSubmitButton(false)
            onSuccess()
        }).catch(error => console.log(error))
            .finally(() => setDisableSubmitButton(false))
    }

    const handleFilesChange = (files) => {
        setFilesToUpload([...files])
    };

    const handleTabChange = (event, newValue) => {
        setResourceType(newValue);
    };


    return <DialogUI header={"Edit Resource"}
                     handleClose={onCloseHandle} open={show}
                     handleSubmit={onSubmit}
                     disableSubmitButton={disableSubmitButton}>
        {
            <>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >

                    <FormControl sx={{mt: 2, minWidth: 300}}>
                        {/*<InputLabel htmlFor="organization">Organization</InputLabel>*/}
                        <NativeSelect value={orgId}
                                      defaultValue={orgId}
                                      fullWidth
                                      name={"organization"}
                                      className={"px-1 border overflow-y"}
                                      autoWidth
                                      inputProps={{
                                          id: 'organization',
                                      }}
                                      label="Organization"
                                      variant="standard"
                                      onChange={event => setOrgId(event.target.value)}>
                            {organizationMenuItems}
                        </NativeSelect>
                    </FormControl>
                    <FormControl sx={{mt: 2, minWidth: 300}}>
                        {/*<InputLabel htmlFor="topic">Topic</InputLabel>*/}
                        <NativeSelect value={topicId}
                                      defaultValue={topicId}
                                      fullWidth
                                      className={"px-1 border overflow-y"}
                                      name={"topic"}
                                      autoWidth
                                      inputProps={{
                                          id: 'topic',
                                      }}
                                      label="Topic"
                                      variant="standard"
                                      onChange={event => setTopicId(event.target.value)}>
                            {topicMenuItems}
                        </NativeSelect>
                    </FormControl>
                    <FormControl sx={{mt: 2, minWidth: 300}}>
                        {/*<InputLabel htmlFor="slot">Slot</InputLabel>*/}
                        <NativeSelect value={slotId}
                                      defaultValue={slotId}
                                      fullWidth
                                      className={"px-1 border overflow-y"}
                                      autoWidth
                                      name={"slot"}
                                      inputProps={{
                                          id: 'slot',
                                      }}
                                      label="Slot"
                                      variant="standard"
                                      onChange={event => setSlotId(event.target.value)}>
                            {slotMenuItems}
                        </NativeSelect>
                    </FormControl>

                    <Box sx={{borderBottom: 1, borderColor: 'divider', mt: 2}}>
                        <Tabs value={resourceType} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Text" {...a11yProps(0)} />
                            <Tab label="PDF Upload" {...a11yProps(1)} />
                            <Tab label="PDF Link" {...a11yProps(2)} />
                            <Tab label="URL" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={resourceType} index={0}>
                        <FormControl sx={{mt: 2, minWidth: 350}}>
                            <TextareaAutosize value={data}
                                              fullWidth={true}
                                              margin="dense"
                                              variant="standard"
                                              className={"px-1 border overflow-y"}
                                              label="Resource Data"
                                              placeholder={"Text Data"}
                                              inputProps={{
                                                  name: 'max-width',
                                                  id: 'data',
                                              }}
                                              autoWidth
                                              autoFocus
                                              minRows={5}
                                              onChange={event => setData(event.target.value)}/>
                        </FormControl>
                    </CustomTabPanel>
                    <CustomTabPanel value={resourceType} index={1}>
                        <FormControl sx={{mt: 2, minWidth: 300}}>
                            <FileUpload
                                multiFile={false}
                                title="Choose PDF file"
                                header="Drag and drop"
                                onFilesChange={handleFilesChange}
                                onContextReady={(context) => {
                                }}
                            />
                            <LinearProgress variant="determinate" value={uploadProgress}/>
                            {currentDocumentName}
                        </FormControl>
                    </CustomTabPanel>
                    <CustomTabPanel value={resourceType} index={2}>
                        <FormControl fullWidth>
                            <TextField value={pdfLink}
                                       fullWidth
                                       margin="dense"
                                       variant="standard"
                                       className={"w-100 px-1 border overflow-y"}
                                       rowsMin={3}
                                       label="PDF Link"
                                       autoFocus
                                       onChange={event => setPdfLink(event.target.value)}/>
                        </FormControl>
                    </CustomTabPanel>
                    <CustomTabPanel value={resourceType} index={3}>
                        <FormControl fullWidth>
                            <TextField value={url}
                                       fullWidth
                                       margin="dense"
                                       variant="standard"
                                       className={"w-100 px-1 border overflow-y"}
                                       rowsMin={3}
                                       label="URL"
                                       autoFocus
                                       onChange={event => setUrl(event.target.value)}/>
                        </FormControl>
                    </CustomTabPanel>
                </Box>
            </>
        }

    </DialogUI>
}
