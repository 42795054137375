import React, {useEffect, useState} from "react";
import {DialogUI, ModalUI} from "../../layout/modal/Modal";
import {Box, Button, FormControl, InputLabel, Select, TextareaAutosize, TextField} from "@mui/material";
import {ResourceRepository} from "./ResourceRepository";
import {toast} from "react-toastify";
import SelectInput from "@mui/material/Select/SelectInput";
import {OrganizationRepository} from "../organizations/OrganizationRepository";
import MenuItem from "@mui/material/MenuItem";
import {TopicRepository} from "../topics/TopicRepository";

export const CreateResource = ({onSuccess, onCloseHandle, show}) => {
    const [name, setName] = useState(null);
    const [data, setData] = useState(null);
    const [url, setUrl] = useState(null);
    const [error, setError] = useState(null);
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)

    const onSubmit = () => {
        setDisableSubmitButton(true)
        if (name && data && url) {
            ResourceRepository.create(url, name, data)
                .then(() => {
                    toast.success("Success");
                    onSuccess();
                }).catch((error) => console.log(error))
                .finally(()=> setDisableSubmitButton(false))
        }

    }


    return <DialogUI header={"Create Resource"}
                     handleClose={onCloseHandle} open={show} handleSubmit={onSubmit} disableSubmitButton={disableSubmitButton}>
        {
            <>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >

                    <FormControl sx={{mt: 2, minWidth: 300}}>
                        <TextField value={name}
                                   fullWidth={true}
                                   margin="dense"
                                   variant="standard"
                                   className={"px-1 border overflow-y"}
                                   label="Resource Name"
                                   autoWidth
                                   autoFocus
                                   onChange={event => setName(event.target.value)}/>
                    </FormControl>
                    <FormControl sx={{mt: 2, minWidth: 300}}>

                        <TextField value={url}
                                   fullWidth={true}
                                   margin="dense"
                                   variant="standard"
                                   className={"px-1 border overflow-y"}
                                   label="Resource URL"
                                   autoWidth
                                   autoFocus
                                   onChange={event => setUrl(event.target.value)}/>

                    </FormControl>
                    <FormControl sx={{mt: 2, minWidth: 300}}>
                        <TextareaAutosize value={data}
                                          fullWidth={true}
                                          margin="dense"
                                          variant="standard"
                                          className={"px-1 border overflow-y"}
                                          label="Resource Data"
                                          placeholder={"JSON Data"}
                                          inputProps={{
                                              name: 'max-width',
                                              id: 'data',
                                          }}
                                          autoWidth
                                          autoFocus
                                          maxRows={10}
                                          minRows={10}
                                          onChange={event => setData(event.target.value)}/>

                    </FormControl>
                </Box>

            </>
        }

    </DialogUI>
}
