import React from "react";
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export const ModalUI = ({header, children, handleSubmit, handleClose, open}) => {
    return <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >

        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                {header}
            </Typography>
            {children}
        </Box>
    </Modal>
}

export const DialogUI = ({header, children, handleSubmit, handleClose, open, disableSubmitButton}) => {

    const actionButtons = () => {
        if (handleSubmit) {
            return (
                <DialogActions>
                    <Button onClick={handleClose} variant={'outlined'}>Cancel</Button>
                    <LoadingButton
                        loading={disableSubmitButton}
                        loadingPosition="start"
                        onClick={handleSubmit}
                        startIcon={<SaveIcon/>}
                        variant="outlined"
                    >
                        Save
                    </LoadingButton>
                    {/*<Button onClick={handleSubmit} variant={'outlined'} disabled={disableSubmitButton}>Submit</Button>*/}
                </DialogActions>
            )
        } else {
            return (
                <DialogActions>
                    <Button onClick={handleClose} variant={'outlined'}>Close</Button>
                    {/*<Button onClick={handleSubmit} variant={'outlined'} disabled={disableSubmitButton}>Submit</Button>*/}
                </DialogActions>
            )
        }
    }

    return <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}>
        <DialogTitle>{header}</DialogTitle>
        <DialogContent>
            {children}

        </DialogContent>
        {actionButtons()}
    </Dialog>
}

