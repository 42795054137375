import axios from "../../axios/axios"


export const ResourceRepository = {
    getAll: () => {
        return axios.get(`/api/resources`, {
            headers: {"Content-Type": "application/json"}
        });
    },
    delete: (id) => {
        return axios.delete(`/api/resources/${id}`)
    },
    create: (url, name, data) => {
        let params = {
            "url": url,
            "name": name,
            "data": data
        }
        return axios.post('/api/resources', params)
    },
    updateData: (id, data) => {
        let params = {
            "data":data
        }
        return axios.post(`/api/resources/${id}`, params)
    }
}