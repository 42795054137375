import React, {useState} from "react";
import {DialogUI, ModalUI} from "../../layout/modal/Modal";
import {Button, FormControl, TextareaAutosize, TextField} from "@mui/material";
import {OrganizationRepository} from "./OrganizationRepository";
import {toast} from "react-toastify";

export const CreateOrganization = ({onSuccess, onCloseHandle, show}) => {
    const [name, setName] = useState(null);
    const [error, setError] = useState(null);

    const onSubmit = () => {
        if (name) {
            OrganizationRepository.create(name)
                .then(() => {
                    toast.success("File uploaded successfully");
                    onSuccess();
                })
        }

    }


    return <DialogUI header={"Create documents from text"}
                     handleClose={onCloseHandle} handleSubmit={onSubmit} open={show}>
        {
            <>

                <FormControl fullWidth>
                    <TextField value={name}
                               fullWidth
                               margin="dense"
                               variant="standard"
                               className={"w-100 px-1 border overflow-y"}
                               rowsMin={3}
                               label="Organization Name"
                               autoFocus
                               onChange={event => setName(event.target.value)}/>
                </FormControl>

            </>
        }

    </DialogUI>
}
