import React, {useEffect, useState} from "react";
import {DialogUI} from "../../layout/modal/Modal";
import {
    Box,
    FormControl,
    InputLabel,
    LinearProgress,
    Select,
    Tab,
    Tabs,
    TextareaAutosize,
    TextField
} from "@mui/material";
import {KnowledgeRepository} from "./KnowledgeRepository";
import {toast} from "react-toastify";
import {OrganizationRepository} from "../organizations/OrganizationRepository";
import MenuItem from "@mui/material/MenuItem";
import {TopicRepository} from "../topics/TopicRepository";
import {SlotRepository} from "../slots/SlotRepository";
import FileUpload from "react-mui-fileuploader"
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ResourceTypes = {
    0: 'text',
    1: 'pdf',
    2: 'onlinepdf',
    3: 'url'
}

export const CreateKnowledge = ({onSuccess, onCloseHandle, show, orgIdParam, topicIdParam, slotIdParam}) => {
    const [organizations, setOrganizations] = useState([]);
    const [topics, setTopics] = useState([]);
    const [slots, setSlots] = useState([]);
    const [name, setName] = useState(null);
    const [data, setData] = useState(null);
    const [url, setUrl] = useState(null);
    const [filesToUpload, setFilesToUpload] = useState([])
    const [pdfLink, setPdfLink] = useState("")
    const [orgId, setOrgId] = useState(""+orgIdParam);
    const [topicId, setTopicId] = useState(""+topicIdParam);
    const [slotId, setSlotId] = useState(""+slotIdParam);
    const [error, setError] = useState(null);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [resourceType, setResourceType] = useState(0)
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)

    useEffect(() => {
        OrganizationRepository.getAll().then(response => {
            setOrganizations(response.data.organizations)
        }).catch(error => console.log(error))

    }, [])

    useEffect(() => {
        setOrgId(orgIdParam);
        setTopicId(topicIdParam);
        setSlotId(slotIdParam);
    }, [orgIdParam, topicIdParam, slotIdParam]);

    useEffect(() => {
        if (orgId) {
            TopicRepository.getAllByOrgId(orgId).then(response => {
                setTopics(response.data)
            }).catch(error => console.log(error))
        }

    }, [orgId])

    useEffect(() => {
        if (topicId) {
            SlotRepository.getAllByTopicId(topicId).then(response => {
                setSlots(response.data)
            }).catch(error => console.log(error))
        }
    }, [topicId])

    const organizationMenuItems = organizations.map((o, index) => {
        return (
            <MenuItem value={o.id}>{o.name}</MenuItem>
        )
    })

    const topicMenuItems = topics.map((o, index) => {
        return (
            <MenuItem value={o.id}>{o.name}</MenuItem>
        )
    })

    const slotMenuItems = slots.map((o, index) => {
        return (
            <MenuItem value={o.id}>{o.name}</MenuItem>
        )
    })

    const onSubmit = () => {
        setDisableSubmitButton(true)
        // Create a form and post it to server
        let formData = new FormData()
        filesToUpload.forEach((file) => formData.append("files", file))
        KnowledgeRepository.create(slotId, data, filesToUpload[0], ResourceTypes[resourceType], url, pdfLink, setUploadProgress).then(response => {
            toast.success("Success");
            setDisableSubmitButton(false)
            onSuccess()
        }).catch(error => console.log(error))
            .finally(() => setDisableSubmitButton(false))
    }

    const handleFilesChange = (files) => {
        setFilesToUpload([...files])
    };

    const handleTabChange = (event, newValue) => {
        setResourceType(newValue);
    };


    return <DialogUI header={"Create Resource"}
                     handleClose={onCloseHandle} open={show}
                     handleSubmit={onSubmit}
                     disableSubmitButton={disableSubmitButton}>
        {
            <>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >

                    <FormControl sx={{mt: 2, minWidth: 300}}>
                        <InputLabel htmlFor="organization">Organization</InputLabel>
                        <Select value={orgId}
                                fullWidth
                                name={"organization"}
                                className={"px-1 border overflow-y"}
                                autoWidth
                                inputProps={{
                                    name: 'max-width',
                                    id: 'organization',
                                }}
                                label="Organization"
                                variant="standard"
                                onChange={event => setOrgId(event.target.value)}>
                            {organizationMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl sx={{mt: 2, minWidth: 300}}>
                        <InputLabel htmlFor="topic">Topic</InputLabel>
                        <Select value={topicId}
                                fullWidth
                                className={"px-1 border overflow-y"}
                                rowsMin={3}
                                rowsMax={10}
                                autoWidth
                                inputProps={{
                                    name: 'max-width',
                                    id: 'topic',
                                }}
                                label="Topic"
                                variant="standard"
                                onChange={event => setTopicId(event.target.value)}>
                            {topicMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl sx={{mt: 2, minWidth: 300}}>
                        <InputLabel htmlFor="slot">Slot</InputLabel>
                        <Select value={slotId}
                                fullWidth
                                className={"px-1 border overflow-y"}
                                rowsMin={3}
                                rowsMax={10}
                                autoWidth
                                inputProps={{
                                    name: 'max-width',
                                    id: 'topic',
                                }}
                                label="Topic"
                                variant="standard"
                                onChange={event => setSlotId(event.target.value)}>
                            {slotMenuItems}
                        </Select>
                    </FormControl>

                    <Box sx={{borderBottom: 1, borderColor: 'divider', mt: 2}}>
                        <Tabs value={resourceType} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Text" {...a11yProps(0)} />
                            <Tab label="PDF Upload" {...a11yProps(1)} />
                            <Tab label="PDF Link" {...a11yProps(2)} />
                            <Tab label="URL" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={resourceType} index={0}>
                        <FormControl sx={{mt: 2, minWidth: 350}}>
                            <TextareaAutosize value={data}
                                              fullWidth={true}
                                              margin="dense"
                                              variant="standard"
                                              className={"px-1 border overflow-y"}
                                              label="Resource Data"
                                              placeholder={"Text Data"}
                                              inputProps={{
                                                  name: 'max-width',
                                                  id: 'data',
                                              }}
                                              autoWidth
                                              autoFocus
                                              minRows={5}
                                              onChange={event => setData(event.target.value)}/>
                        </FormControl>
                    </CustomTabPanel>
                    <CustomTabPanel value={resourceType} index={1}>
                        <FormControl sx={{mt: 2, minWidth: 300}}>
                            <FileUpload
                                multiFile={false}
                                title="Choose PDF file"
                                header="Drag and drop"
                                onFilesChange={handleFilesChange}
                                onContextReady={(context) => {
                                }}
                            />
                            <LinearProgress variant="determinate" value={uploadProgress}/>
                        </FormControl>
                    </CustomTabPanel>
                    <CustomTabPanel value={resourceType} index={2}>
                        <FormControl fullWidth>
                            <TextField value={pdfLink}
                                       fullWidth
                                       margin="dense"
                                       variant="standard"
                                       className={"w-100 px-1 border overflow-y"}
                                       rowsMin={3}
                                       label="PDF Link"
                                       autoFocus
                                       onChange={event => setPdfLink(event.target.value)}/>
                        </FormControl>
                    </CustomTabPanel>
                    <CustomTabPanel value={resourceType} index={3}>
                        <FormControl fullWidth>
                            <TextField value={url}
                                       fullWidth
                                       margin="dense"
                                       variant="standard"
                                       className={"w-100 px-1 border overflow-y"}
                                       rowsMin={3}
                                       label="URL"
                                       autoFocus
                                       onChange={event => setUrl(event.target.value)}/>
                        </FormControl>
                    </CustomTabPanel>


                </Box>

            </>
        }

    </DialogUI>
}
