import {Box, Stack} from "@mui/material";
import {Navigation} from "./nav/Navigation";
import {useState} from "react";

export const MainLayout = ({children}) => {
    // when screen is xs-sm than open drawer from left

    return (
        <Stack
            direction={"column"}
            sx={{width: '100%', height: '100%'}}
        >
            <Navigation openSideBar={null} onOpenSideBar={() => {
            }}/>
            <Box
                className={"bg-secondary"}
                sx={{flex: 1, p: 2}}
            >
                {children}
            </Box>
        </Stack>
    )
}