import axios from "../../axios/axios"


export const SlotRepository = {
    getAll: () => {
        return axios.get(`/api/slot`, {
            headers: {"Content-Type": "application/json"}
        });
    },
    delete: (id) => {
        return axios.delete(`/api/slot/${id}`)
    },
    create: (name, orgId, topicId) => {
        let data = {
            "org_id": orgId,
            "topic_id": topicId,
            "name": name
        }
        return axios.post('/api/slot', data)
    },
    getAllByTopicId: (topicId) => {
        return axios.get(`/api/slot/topic/${topicId}`, {
            headers: {"Content-Type": "application/json"}
        });
    },
    getById: (slotId) => {
        return axios.get(`/api/slot/${slotId}`, {
            headers: {"Content-Type": "application/json"}
        })
    }
}