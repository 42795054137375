import React, {useEffect, useState} from "react";
import {Box, Button, IconButton, TextField} from "@mui/material";
import {DialogUI} from "../../layout/modal/Modal";
import {Add, AddOutlined, DeleteForever, Edit, HdrPlus, PlusOne} from "@mui/icons-material";
import {MetadataRepository} from "./MetadataRepository";

export const AddMetadata = ({show, onCloseHandle, onSubmit, knowledgeItem}) => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [items, setItems] = useState([])
    const [verb, setVerb] = useState("")
    const [value, setValue] = useState("")
    const [knowledgeItemId, setKnowledgeItemId] = useState({})

    const fields = items.map((k, index) => {
        return (
            <div>
                <TextField
                    disabled
                    id="verb"
                    label="Verb"
                    size="small"
                    value={k.verb}
                    defaultValue=""
                />
                <TextField
                    disabled
                    id="value"
                    label="Object"
                    size="small"
                    value={k.value}
                    defaultValue=""
                />
                <IconButton
                    sx={{"py":2}}
                    title={"Delete"}
                    onClick={() => deleteItem(k.id)} title={"Delete"} component={Button}
                    color={"primary"}>
                    <DeleteForever/>
                </IconButton>
            </div>
        )
    })

    const addItem = (verb, value) => {
        let new_item = {"verb":verb,"value":value}
        MetadataRepository.addMetadataToKnowledgeItemId(knowledgeItem.id, new_item).then(()=>{
            fetchMetadataByKnowledgeItem(knowledgeItem.id)
        })
    }

    const save = () => {
        addItem(verb, value)
        setVerb("")
        setValue("")
    }

    const deleteItem = (metadataId) => {
        MetadataRepository.deleteById(metadataId).then(()=>{
            fetchMetadataByKnowledgeItem(knowledgeItem.id)
        })
    }

    const fetchMetadataByKnowledgeItem = (knowledgeId) => {
        MetadataRepository.getByKnowledgeItemId(knowledgeId).then(response=> {
            setItems(response.data)
        })
    }

    const saveAndPublish = () => {
        save()
        onSubmit(knowledgeItem)
    }

    useEffect(()=>{
        if (knowledgeItem && knowledgeItem.hasOwnProperty("id")) {
            setKnowledgeItemId(knowledgeItem.id)
            fetchMetadataByKnowledgeItem(knowledgeItem.id)
        }
    },[knowledgeItem])

    // useEffect(()=>{
    //     debugger;
    //     if (knowledgeItemId) {
    //         fetchMetadataByKnowledgeItem(knowledgeItemId)
    //     }
    // },[knowledgeItemId])


    return <DialogUI header={"Add Metadata"}
                     handleClose={onCloseHandle} open={show}
                     handleSubmit={saveAndPublish}
                     disableSubmitButton={disableSubmitButton}>
            <>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off">

                    {fields}

                    <div>
                        <TextField
                            required
                            id="verb"
                            label="Verb"
                            defaultValue=""
                            value={verb}
                            onChange={event => setVerb(event.target.value)}
                            size="small"
                        />
                        <TextField
                            required
                            id="value"
                            label="Object"
                            defaultValue=""
                            value={value}
                            onChange={event => setValue(event.target.value)}
                            size="small"
                        />
                        <IconButton
                            sx={{"py":2}}
                            title={"Add New"}
                            onClick={() => save()} title={"Add New"} component={Button}
                            color={"primary"}>
                            <AddOutlined/>
                        </IconButton>
                    </div>
                </Box>

            </>

    </DialogUI>
}