export const documentsMockData = [
  {
    "content": "Hello, how may I assist you today?",
    "status": "true"
  },
  {
    "content": "I am experiencing issues with my internet connection.",
    "status": "true"
  },
  {
    "content": "I apologize for the inconvenience. Let me check your connection.",
    "status": "true"
  },
  {
    "content": "Thank you.",
    "status": "true"
  },
  {
    "content": "Your internet connection seems to be stable. Is there anything else I can help you with?",
    "status": "true"
  },
  {
    "content": "No, that will be all. Thank you.",
    "status": "true"
  },
  {
    "content": "You're welcome. Have a great day!",
    "status": "true"
  },
  {
    "content": "Hello, thank you for contacting us. How can I assist you?",
    "status": "true"
  },
  {
    "content": "I am having trouble making calls from my phone.",
    "status": "true"
  },
  {
    "content": "I apologize for the inconvenience. Let me check your phone line.",
    "status": "true"
  },
  {
    "content": "Thank you.",
    "status": "true"
  },
  {
    "content": "I have checked your phone line and it seems to be working fine. Is there anything else I can assist you with?",
    "status": "true"
  },
  {
    "content": "No, that will be all. Thank you.",
    "status": "true"
  },
  {
    "content": "You're welcome. Have a great day!",
    "status": "true"
  },
  {
    "content": "Hello, how can I help you today?",
    "status": "true"
  },
  {
    "content": "I am unable to send text messages from my phone.",
    "status": "true"
  },
  {
    "content": "I'm sorry to hear that. Let me check your messaging service.",
    "status": "true"
  },
  {
    "content": "Thank you.",
    "status": "true"
  },
  {
    "content": "Your messaging service is active and running. Is there anything else I can assist you with?",
    "status": "true"
  },
  {
    "content": "No, that will be all. Thank you.",
    "status": "true"
  },
  {
    "content": "You're welcome. Have a great day!",
    "status": "true"
  },
  {
    "content": "Hello, thank you for contacting us. How can I assist you today?",
    "status": "true"
  },
  {
    "content": "I am having trouble accessing the internet on my laptop.",
    "status": "true"
  },
  {
    "content": "I apologize for the inconvenience. Let me check your laptop's connection.",
    "status": "true"
  },
  {
    "content": "Thank you.",
    "status": "true"
  },
  {
    "content": "Your laptop's connection seems to be stable. Is there anything else I can help you with?",
    "status": "true"
  },
  {
    "content": "No, that will be all. Thank you.",
    "status": "true"
  },
  {
    "content": "You're welcome. Have a great day!",
    "status": "true"
  },
  {
    "content": "Hello, how may I assist you today?",
    "status": "true"
  },
  {
    "content": "I am experiencing issues with my internet connection.",
    "status": "true"
  },
  {
    "content": "I apologize for the inconvenience. Let me check your connection.",
    "status": "true"
  },
  {
    "content": "Thank you.",
    "status": "true"
  },
  {
    "content": "Your internet connection seems to be stable. Is there anything else I can help you with?",
    "status": "true"
  },
  {
    "content": "No, that will be all. Thank you.",
    "status": "true"
  },
  {
    "content": "You're welcome. Have a great day!",
    "status": "true"
  },
  {
    "content": "Hello, thank you for contacting us. How can I assist you?",
    "status": "true"
  },
  {
    "content": "I am having trouble making calls from my phone.",
    "status": "true"
  },
  {
    "content": "I apologize for the inconvenience. Let me check your phone line.",
    "status": "true"
  },
  {
    "content": "Thank you.",
    "status": "true"
  },
  {
    "content": "I have checked your phone line and it seems to be working fine. Is there anything else I can assist you with?",
    "status": "true"
  },
  {
    "content": "No, that will be all. Thank you.",
    "status": "true"
  },
  {
    "content": "You're welcome. Have a great day!",
    "status": "true"
  },
  {
    "content": "Hello, how can I help you today?",
    "status": "true"
  },
  {
    "content": "I am unable to send text messages from my phone.",
    "status": "true"
  },
  {
    "content": "I'm sorry to hear that. Let me check your messaging service.",
    "status": "true"
  },
  {
    "content": "Thank you.",
    "status": "true"
  },
  {
    "content": "Your messaging service is active and running. Is there anything else I can assist you with?",
    "status": "true"
  },
  {
    "content": "No, that will be all. Thank you.",
    "status": "true"
  },
  {
    "content": "You're welcome. Have a great day!",
    "status": "true"
  },
  {
    "content": "Hello, thank you for contacting us. How can I assist you today?",
    "status": "true"
  },
  {
    "content": "I am having trouble accessing the internet on my laptop.",
    "status": "true"
  },
  {
    "content": "I apologize for the inconvenience. Let me check your laptop's connection.",
    "status": "true"
  },
  {
    "content": "Thank you.",
    "status": "true"
  },
  {
    "content": "Your laptop's connection seems to be stable. Is there anything else I can help you with?",
    "status": "true"
  },
  {
    "content": "No, that will be all. Thank you.",
    "status": "true"
  },
  {
    "content": "You're welcome. Have a great day!",
    "status": "true"
  }
]