import {AppBar, Button, IconButton, SvgIcon, Toolbar} from "@mui/material";
import {Link} from "react-router-dom";
import {IconMenu} from "@tabler/icons-react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {useState} from "react";

export const Navigation = ({openSideBar, onOpenSideBar}) => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const pages = [{'name': 'Organizations', 'url': '/organizations'},
        {'name': 'Topics', 'url': '/topics'},
        {'name': 'Slots', 'url': '/slots'},
        {'name': 'Knowledge', 'url': '/knowledge'},
        {'name': 'Resources', 'url': '/resources'}];

    const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

    return (
        <AppBar elevation={0} position={"sticky"}>
            <Toolbar disableGutters sx={{"pl":3,"pr":3}}>
                <img src={"/logo.svg"} alt={"Logo"} width={70} height={"auto"}/>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="#app-bar-with-responsive-menu"
                    sx={{
                        mr: 2,
                        display: {xs: 'none', md: 'flex'},
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    KNOWLEDGE
                </Typography>

                <Box sx={{flexGrow: 1.1, display: {xs: 'none', md: 'flex'}}}>
                    {pages.map((page) => (
                        <Button key={page.url} sx={{ color: '#fff' }}
                            variant="outlined"
                            href={page.url}
                        >
                            {page.name}
                        </Button>
                    ))}
                </Box>

                <Box sx={{flexGrow: 0}}>
                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg"/>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{mt: '45px'}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">{setting}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    )
}