import axios from "../../axios/axios"


export const TopicRepository = {
    getAll: () => {
        return axios.get(`/api/topic`, {
            headers: {"Content-Type": "application/json"}
        });
    },
    delete: (id) => {
        return axios.delete(`/api/topic/${id}`)
    },
    create: (name, orgId) => {
        let data = {
            "org_id": orgId,
            "name": name
        }
        return axios.post('/api/topic', data)
    },
    getAllByOrgId(orgId) {
        return axios.get(`/api/topic/organization/${orgId}`, {
            headers: {"Content-Type": "application/json"}
        });
    }
}