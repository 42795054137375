import {Box, Stack} from "@mui/material";
import {Navigation} from "../main-layout/nav/Navigation";
import {useState} from "react";
import {SideBar} from "./side-bar/SideBar";

export const ProjectLayout = ({children}) => {
  // when screen is xs-sm than open drawer from left
  const [openSideBar, setOpenSideBar] = useState(false);

  return (
    <Stack
      direction={"column"}
      sx={{minHeight: '100%'}}
    >
      <Navigation onOpenSideBar={() => setOpenSideBar(true)} />
      <Stack
        direction={"row"}
        // alignItems={"stretch"}
        sx={{ flex: 1 }}
      >
        <SideBar open={openSideBar} onClose={() => setOpenSideBar(false)} />
        <Stack
          direction={"column"}
          className={"bg-secondary"}
          sx={{ flex: 1, pl: {xs: 0, md: 2}, pt: {xs: 0, md: 2}, borderTopLeftRadius: 15}}
        >
          <Box
            sx={{flex: 1, overflowY: "auto", bgcolor: "#FFF", borderTopLeftRadius: 15, px: 2, pt: 2}}
          >
            {children}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}