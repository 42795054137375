import React, {useEffect, useState} from "react";
import {Box, Button, IconButton, TextField} from "@mui/material";
import {DialogUI} from "../../layout/modal/Modal";
import {KnowledgeRepository} from "./KnowledgeRepository";
import Typography from "@mui/material/Typography";
import {AddOutlined, DeleteForeverOutlined} from "@mui/icons-material";
// import {Toast} from "react-toastify/dist/components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const PreviewKnowledge = ({show, onCloseHandle, knowledgeItem}) => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [items, setItems] = useState([])
    const [verb, setVerb] = useState("")
    const [value, setValue] = useState("")
    const [knowledgeItemId, setKnowledgeItemId] = useState({})

    // toast.configure();

    const docs = items.map((k, index) => {
        return (
            <div>
                <Typography fontSize={"small"}>
                    {k.content}
                </Typography>
                <Typography fontSize={"x-small"} align={"right"}>
                    {JSON.stringify(k.metadata)}
                    <IconButton
                        sx={{"py":2}}
                        title={"Delete"}
                        onClick={() => deleteUUID(k.metadata["_additional"]["id"])} title={"Delete"} component={Button}
                        color={"primary"}>
                        <DeleteForeverOutlined/>
                    </IconButton>
                </Typography>
                <br/>
            </div>
        )
    })

    const deleteUUID = (uuid) => {
        KnowledgeRepository.deleteByUUID(knowledgeItemId,uuid).then(response => {
            toast.success("Success")
            loadData()
        }).catch(e => {
            alert(e.message)
            toast.error(e.message)
        })
    }

    const fetch = (knowledgeId) => {
        KnowledgeRepository.preview(knowledgeId).then(response=> {
            setItems(response.data.docs)
        })
    }

    const loadData = () => {
        if (knowledgeItem && knowledgeItem.hasOwnProperty("id")) {
            setKnowledgeItemId(knowledgeItem.id)
            fetch(knowledgeItem.id)
        }
    }

    useEffect(()=>{
        loadData()
    },[knowledgeItem])


    return <DialogUI header={"Preview Ingested Data"}
                     handleClose={onCloseHandle} open={show}
                     disableSubmitButton={disableSubmitButton}>
        <>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off">
                {items.length > 0 &&
                    docs
                }
                {items.length === 0 &&
                    (<div>No Ingested Data for this document</div>)
                }


            </Box>

        </>

    </DialogUI>
}