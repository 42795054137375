import axios from "../../axios/axios"


export const OrganizationRepository = {
    getAll: () => {
        return axios.get(`/api/organizations`, {
            headers: {"Content-Type": "application/json"}
        });
    },
    delete: (id) => {
        return axios.delete(`/api/organizations/${id}`)
    },
    create: (orgName) => {
        let data = {
            "name":orgName
        }
        return axios.post('/api/organizations', data)
    }
}