import {Box} from "@mui/material";

export const BlockCard = ({sx, children}) => {
  return (
    <Box
      sx={{bgcolor: "#FFF", borderRadius: 5, ...sx}}
    >
      {children}
    </Box>
  )
}