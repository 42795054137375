import React, {useEffect, useState} from "react";
import {DialogUI, ModalUI} from "../../layout/modal/Modal";
import {Box, Button, FormControl, InputLabel, Select, TextareaAutosize, TextField} from "@mui/material";
import {TopicRepository} from "./TopicRepository";
import {toast} from "react-toastify";
import SelectInput from "@mui/material/Select/SelectInput";
import {OrganizationRepository} from "../organizations/OrganizationRepository";
import MenuItem from "@mui/material/MenuItem";

export const CreateTopic = ({onSuccess, onCloseHandle, show}) => {
    const [name, setName] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const [orgId, setOrgId] = useState(null);
    const [error, setError] = useState(null);
    const [percent, setPercent] = useState(null);

    const onSubmit = () => {
        if (name && orgId) {
            TopicRepository.create(name,orgId)
                .then(() => {
                    toast.success("File uploaded successfully");
                    onSuccess();
                })
        }

    }

    useEffect(() => {
        OrganizationRepository.getAll().then(response => {
            setOrganizations(response.data.organizations)
        }).catch(error => console.log(error))
    },[])

    const organizationMenuItems = organizations.map((o, index) => {
        return (
            <MenuItem value={o.id}>{o.name}</MenuItem>
        )
    })


    return <DialogUI header={"Create topic from text"}
                     handleClose={onCloseHandle} open={show} handleSubmit={onSubmit}>
        {
            <>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >
                    <FormControl sx={{mt: 2, minWidth: 300}}>
                        <TextField value={name}
                                   fullWidth={true}
                                   margin="dense"
                                   variant="standard"
                                   className={"px-1 border overflow-y"}
                                   label="Topic Name"
                                   autoWidth
                                   autoFocus
                                   onChange={event => setName(event.target.value)}/>
                    </FormControl>
                    <FormControl sx={{mt: 2, minWidth: 300}}>
                        <InputLabel htmlFor="organization">Organization</InputLabel>
                        <Select value={orgId}
                                fullWidth
                                className={"px-1 border overflow-y"}
                                rowsMin={3}
                                rowsMax={10}
                                autoWidth
                                inputProps={{
                                    name: 'max-width',
                                    id: 'organization',
                                }}
                                label="Organization"
                                variant="standard"
                                onChange={event => setOrgId(event.target.value)}>
                            {organizationMenuItems}
                        </Select>
                    </FormControl>
                </Box>

                {/*<div className={"my-2 d-flex"}>*/}
                {/*    <Button*/}
                {/*        size={"small"}*/}
                {/*        type={"button"}*/}
                {/*        color={"primary"}*/}
                {/*        disabled={!name}*/}
                {/*        onClick={submit}*/}
                {/*        className={"me-1"}>Submit</Button>*/}
                {/*    <Button*/}
                {/*        size={"small"}*/}
                {/*        type={"button"}*/}
                {/*        color={"secondary"}*/}
                {/*        onClick={onCloseHandle}*/}
                {/*        className={"me-1"}>Cancel</Button>*/}
                {/*</div>*/}
            </>
        }

    </DialogUI>
}
