import {MainLayout} from "../../layout/main-layout/MainLayout";
import {BlockCard} from "../../components/block-card/BlockCard";
import {Link} from "react-router-dom";
import {Box, Button, Stack, Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import {IconPlus} from "@tabler/icons-react";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {paginationInitialState} from "../../utils/paginationInitialState";
import {OrganizationRepository} from "./OrganizationRepository";
import {confirmDialogOptions} from "../../utils/confirmDialogOptions"
import {confirm} from "react-confirm-box";
import {CreateOrganization} from "./CreateOrganization"

export const OrganizationPage = () => {
    const [pagination, setPagination] = useState({...paginationInitialState});
    const [organizations, setOrganizations] = useState([]);
    const [createOrganization, setCreateOrganization] = useState(false);

    const fetchData = () => {
        OrganizationRepository.getAll()
            .then(response => {
                setOrganizations(response.data.organizations);
            })
            .catch(error => console.log(error))
    }

    const onDeleteHandle = async (organization) => {
        const response = await confirm(`Are you sure you want to delete '${organization?.name}'`, confirmDialogOptions)
        if (response) {
            OrganizationRepository.delete(organization.id)
                .then(response => {
                    fetchData();
                    toast.success("Document deleted");
                })
                .catch(error => toast.error(error.message))
        }
    }


    const tableBody = organizations.map((organization, index) => {

        return (
            <TableRow hover>
                <TableCell>{organization.id}</TableCell>
                <TableCell>{organization.name}</TableCell>
                <TableCell>
                    <Button onClick={()=>onDeleteHandle(organization)} title={"Delete"} component={Button} color={"primary"}>
                        Delete
                    </Button>
                </TableCell>
            </TableRow>
        )
    })

    useEffect(()=>{
        fetchData();
    },[])

    return (
        <MainLayout>
            <CreateOrganization
                onSuccess={() => {
                    setCreateOrganization(null);
                    fetchData();
                }}
                onCloseHandle={() => setCreateOrganization(null)}
                show={createOrganization}
            />

            <BlockCard sx={{
                flex: 1,
                maxHeight: "100%",
                maxWidth: "xd",
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                p: 2
            }}>
                <Stack direction={"row"}>
                    <Button variant={"outlined"} size={"small"} sx={{borderRadius: 1}} startIcon={<IconPlus/>}
                            onClick={() => setCreateOrganization(true)}>
                        Create Organization
                    </Button>
                </Stack>
                <Box sx={{flex: 1, overflowY: "auto"}}>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontWeight: "700"}}>Id</TableCell>
                                <TableCell sx={{fontWeight: "700"}}>Name</TableCell>
                                <TableCell sx={{fontWeight: "700"}}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableBody}
                        </TableBody>
                    </Table>
                </Box>
                <Stack justifyContent={"space-between"} direction={"row"}>
                    <div/>
                    <TablePagination
                        component="div"
                        count={pagination.totalElements}
                        page={pagination.page}
                        onPageChange={(e, newPage) => setPagination({...pagination, page: newPage})}
                        rowsPerPage={pagination.size}
                        onRowsPerPageChange={(e) => setPagination({
                            ...pagination,
                            size: parseInt(e.target.value, 10),
                            page: 0
                        })}
                    />
                </Stack>
            </BlockCard>
        </MainLayout>
    )
}